@import '../../variables';
@media screen and (min-width: 768px) {
#call-to-action-container{
    background: rgb(0,5,123);
    background: linear-gradient(180deg, rgba(0,5,123,1) 0%, rgba(1,83,255,1) 100%);
    margin: 0;
    padding: 4vh 0;
    min-height: 45vh;
    #cta-title{
        font-size: 2.2em;
        color: $text-white;
        margin: 0 0 4vh 0;
        padding: 0;
    }
    .cta-btn{
        transition: .3s;
        &:hover{
            transform: scale(1.05);
        }
    }
    #cta-wp-btn{
        border: 3px solid #FFFFFF;
        border-radius: 32px;
        background-color: rgba(255, 255, 255, .5);
        color: $text-white;
        padding: 6px 0;
        width: 265px;
        font-size: 1em;
        font-weight: 600;
        margin: 0 4px;
        i {
            margin-right: 4px;
            font-size: 1.2em;
        }
    }
    #cta-message-btn{
        border: 3px solid #FFFFFF;
        border-radius: 32px;
        background-color: #FFFFFF;
        color: $primary;
        padding: 6px 0;
        width: 265px;
        font-size: 1em;
        font-weight: 600;
        margin: 0 4px;
    }
}
}
@media screen and (min-width: 768px) {
    #call-to-action-container{
        background: rgb(0,5,123);
        background: linear-gradient(180deg, rgba(0,5,123,1) 0%, rgba(1,83,255,1) 100%);
        margin: 0;
        padding: 8vh 0;
        min-height: 40vh;
        #cta-title{
            font-size: 1.8em;
            color: $text-white;
            margin: 0 0 3vh 0;
            padding: 0;
        }
        .cta-btn{
            transition: .3s;
            &:hover{
                transform: scale(1.05);
            }
        }
        #cta-wp-btn{
            border: 3px solid #FFFFFF;
            border-radius: 32px;
            background-color: rgba(255, 255, 255, .5);
            color: $text-white;
            padding: 6px 0;
            width: 265px;
            font-size: 1em;
            font-weight: 600;
            margin: 0 auto;
            i {
                margin-right: 4px;
                font-size: 1.2em;
            }
        }
        #cta-message-btn{
            border: 3px solid #FFFFFF;
            border-radius: 32px;
            background-color: #FFFFFF;
            color: $primary;
            padding: 6px 0;
            width: 265px;
            font-size: 1em;
            font-weight: 600;
            margin: 10px auto 0 auto;
        }
    }
}